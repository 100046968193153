import phoneGif from '../../assets/images/phone-ringing.gif';
import Logo from '../svgs/logo';

const Header = () => {
    return (
        <header className="my-4 flex items-center justify-between">
            <Logo classes={'w-40 lg:w-48'} />
            <div className="flex items-center justify-between gap-x-2">
                <span className="text-md hidden font-medium lg:block">
                    Need help?
                </span>
                <span className="hidden text-xl font-extrabold lg:block">
                    Call toll free:
                </span>
                <div className="flex">
                    <img src={phoneGif} className="h-12 w-12" />
                    <div className="flex flex-col">
                        <a
                            href="tel:1-866-650-0567"
                            className="text-md font-extrabold lg:text-xl"
                        >
                            1-866-650-0567
                        </a>
                        <span className="text-xs font-medium lg:text-xs">
                            Sun - Sat 8 AM - 8 PM EST
                        </span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
