import { CheckIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AgentImg from '../../assets/images/agent-head.png';
import PulseAnimation from '../../assets/images/pulse.gif';
import useContacts from '../../hooks/useContacts';
import useForm from '../../hooks/useForm';
import Layout from '../shared/Layout';
import Check from '../svgs/check';

const steps = [
    'Quote Requested',
    'Matching you with local providers in your area',
    'Compare Quotes',
];

const Completion = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const progress = (currentStep / (steps.length - 1)) * 100;
    const { data: formData } = useForm();
    const { createContact, sendContactToGHL } = useContacts();
    const navigate = useNavigate();

    useEffect(() => {
        const process = async () => {
            try {
                if (!formData.isCompleted) navigate('/form');
                const contact = await createContact(formData);
                localStorage.setItem('uuid', contact._id);
                setCurrentStep(prevStep => prevStep + 1);
                await sendContactToGHL(contact._id);
                setCurrentStep(prevStep => prevStep + 1);
            } catch (err) {}
        };
        process();
    }, []);

    return (
        <Layout>
            <section className="animation-fadein border-1 mx-auto mt-28 flex flex-col items-center gap-y-5 rounded-lg border border-gray-200 md:w-5/6">
                <div className="-translate-y-1/2 rounded-full border border-2 border-blue-500 p-2">
                    <img
                        src={AgentImg}
                        className="h-28 w-28 rounded-full bg-blue-500 "
                    />
                </div>
                <img
                    src={PulseAnimation}
                    className="mt-3 box-border -translate-y-36 translate-x-12 p-1.5"
                />
                <h1 className="-mt-36 text-center text-2xl font-bold uppercase md:text-3xl">
                    {formData.firstname} , Your Quote is Ready!
                </h1>
                <h1 className="text-center text-xl font-semibold">
                    An agent is calling you right now from the following number
                </h1>
                <a
                    href="tel:1-866-650-0567"
                    className="text-center text-3xl font-bold text-blue-500"
                >
                    1-866-650-0567
                </a>
                <div className="flex">
                    <Check
                        height={20}
                        className={`text-bold mr-2 w-4 text-blue-500`}
                    />
                    <h1 className="text-[.8 rem] font-semibold">
                        The best rates are available via phone
                    </h1>
                </div>
                <div className="relative mx-auto flex w-[80%] w-full items-center justify-center border border-gray-200 bg-gray-100">
                    <div className="mt-14 w-3/4 md:w-1/2">
                        <div className="h-1.5 rounded-full bg-gray-300">
                            <div
                                className="flex h-1.5 rounded-full bg-blue-600 duration-500 dark:bg-blue-500"
                                style={{ width: `${progress}%` }}
                            />
                        </div>
                        <div className="mb-5 flex justify-between text-xs uppercase">
                            {steps.map((step, i) => {
                                return (
                                    <div
                                        key={`${i}-completion`}
                                        className={`items-center ${i === 0 ? '-translate-x-1/2' : ''} ${i === steps.length - 1 ? 'translate-x-1/2' : ''} flex -translate-y-6 flex-col `}
                                    >
                                        <div
                                            className={`w-12 rounded-full border-2 border-${i < currentStep + 1 ? 'blue-500' : 'gray-500'} mb-5 bg-white`}
                                        >
                                            <Check
                                                className={`text-bold p-2 text-${i < currentStep + 1 ? 'blue-500' : 'gray-500'}`}
                                            />
                                        </div>
                                        <p className="text-center font-semibold text-gray-500">
                                            {step}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default memo(Completion);
