import React from 'react';
import useForm from '../../../hooks/useForm';
import PrimaryButton from '../../shared/button/Primary';
import StepWrapper from '../StepWrapper';

const CoverageType = () => {
    const { inputChangeHandler, nextStep } = useForm();

    const handleSubmit = value => {
        inputChangeHandler('coverage_type', value);
        nextStep();
    };

    return (
        <StepWrapper
            title={'Are you looking for individual or family coverage?'}
            hideButtons={true}
        >
            <PrimaryButton
                className="mx-auto mb-3 w-full border border-blue-700 uppercase shadow md:w-1/2"
                onClick={() => handleSubmit('individual')}
            >
                individual
            </PrimaryButton>
            <PrimaryButton
                className="mx-auto mb-3 w-full border border-blue-700 uppercase shadow md:w-1/2"
                onClick={() => handleSubmit('family')}
            >
                family
            </PrimaryButton>
        </StepWrapper>
    );
};

export default CoverageType;
