import useForm from '../../../hooks/useForm';
import Dropdown from '../../shared/form/Dropdown';
import StepWrapper from '../StepWrapper';

const incomeOptions = [
    { name: 'below $23000', value: 'below $23000' },
    { name: '$23000 - $35000', value: '$23000 - $35000' },
    { name: '$35000 - $45000', value: '$35000 - $45000' },
    { name: '$45000 - $55000', value: '$45000 - $55000' },
    { name: '$55000 - $70000', value: '$55000 - $70000' },
    { name: '$70000 - $85000', value: '$70000 - $85000' },
    { name: 'over $85000', value: 'over $85000' },
];

const Income = () => {
    const { data } = useForm();
    return (
        <StepWrapper title={"What's your household income?"}>
            <div className="mx-auto mb-10 md:w-1/2">
                <Dropdown
                    label="household income"
                    options={incomeOptions}
                    defaultValue={
                        incomeOptions.find(
                            income => income.value === data.income
                        ) || incomeOptions[0]
                    }
                    id={'income'}
                />
            </div>
        </StepWrapper>
    );
};

export default Income;
