const Check = ({ className, height }) => {
    return (
        <svg
            className={className}
            height={height}
            fill="currentColor"
            viewBox="-4 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                {' '}
                <title>check</title>{' '}
                <path d="M19.375 5.063l-9.5 13.625-6.563-4.875-3.313 4.594 11.188 8.531 12.813-18.375z"></path>{' '}
            </g>
        </svg>
    );
};

export default Check;
