import React from 'react';
import useForm from '../../../hooks/useForm';
import InputField from '../../shared/form/InputField';
import StepWrapper from '../StepWrapper';

const DateOfBirth = () => {
    const { data } = useForm();

    return (
        <StepWrapper title={"What's your date of birth?"}>
            <div className="mx-auto mb-10 grid w-full grid-cols-3 gap-x-2 md:w-1/2">
                <InputField
                    label="month"
                    placeholder="MM"
                    id={'dob-month'}
                    type="number"
                    minimalError={true}
                    maxLength={12}
                    minLength={0}
                />
                <InputField
                    label="day"
                    placeholder="DD"
                    id={'dob-day'}
                    type="number"
                    minimalError={true}
                    maxLength={new Date(
                        data.dob.year,
                        data.dob.month,
                        0
                    ).getDate()}
                    minLength={0}
                />
                <InputField
                    label="year"
                    placeholder="YYYY"
                    id={'dob-year'}
                    type="number"
                    minimalError={true}
                    maxLength={new Date().getFullYear() - 18}
                    minLength={1900}
                />
            </div>
        </StepWrapper>
    );
};

export default DateOfBirth;
