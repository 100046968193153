import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Completion from './components/Completion';
import Form from './components/Form';
import Home from './components/Home';
import Plans from './components/Plans';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import './index.css';

function App() {
    return (
        <Router>
            <div className="mx-auto min-h-screen w-full bg-white px-5 md:w-2/3 md:px-0">
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/completion" element={<Completion />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
