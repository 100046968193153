import React from 'react';
import { useNavigate } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import Back from '../shared/button/Back';
import Next from '../shared/button/Next';

const StepWrapper = ({
    children,
    title,
    subtitle,
    subtitleIcon,
    hideButtons,
    submit,
}) => {
    const { previousStep, nextStep, inputChangeHandler } = useForm();
    const navigate = useNavigate();
    const handleSubmit = async () => {
        nextStep(
            submit
                ? () => {
                      inputChangeHandler('isCompleted', true);
                      navigate('/completion');
                  }
                : undefined
        );
    };
    return (
        <>
            <h1 className="my-10 text-center text-2xl font-bold md:text-3xl">
                {title}
            </h1>
            {subtitle && (
                <p className="mb-6 flex items-center justify-center gap-x-1 italic">
                    {subtitleIcon}
                    {subtitle}
                </p>
            )}
            {children}
            {!hideButtons && (
                <>
                    {submit ? (
                        <>
                            <p className="mx-auto mb-4 text-sm italic md:w-1/2">
                                By clicking the 'Get My Free Quotes' Button, I
                                agree to the consents below the button.
                            </p>
                            <Next
                                text="get my free quotes"
                                onClick={handleSubmit}
                            />
                            <p className="mt-12 text-sm">
                                By clicking the Get My Free Quotes button and
                                submitting this form, I agree that I am 18+
                                years old and I provide my signature expressly
                                consenting to receive emails, calls, postal
                                mail, text messages and other forms of marketing
                                communication regarding Health Insurance, or
                                other offers from the listed companies and
                                agents to the number(s) I provided, including a
                                mobile phone, even if I am on a state or federal
                                Do Not Call and/or Do Not Email registry. The
                                list of companies participating are subject to
                                change. I will receive calls and/or texts from
                                multiple companies in the list. Such calls and
                                text messages may use automated telephone
                                dialing systems, artificial or pre-recorded
                                voices. I understand my wireless carrier may
                                impose charges for calls or texts. I understand
                                that my consent to receive communications is not
                                a condition of purchase and I may revoke my
                                consent at any time by calling us at
                                833-637-0318.
                            </p>
                        </>
                    ) : (
                        <Next onClick={handleSubmit} />
                    )}
                    <Back onClick={previousStep} />
                </>
            )}
        </>
    );
};

export default StepWrapper;
