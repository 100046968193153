import { useFormContext } from '../context/FormContext';

const useForm = () => {
    const { state, dispatch } = useFormContext();

    const setForm = data => {
        dispatch({
            type: 'SET_DATA',
            payload: data,
        });
    };

    const setErrorIds = ids => {
        dispatch({
            type: 'SET_ERROR_IDS',
            payload: ids,
        });
    };

    const getFieldValue = id => {
        const keys = id.split('-');
        if (keys.length === 1) return state.data[id];
        else return state.data[keys[0]][keys[1]];
    };
    const updateFormField = (id, value) => {
        dispatch({
            type: 'UPDATE_INPUT_FIELD',
            payload: {
                id,
                value,
            },
        });
    };

    const inputChangeHandler = (key, value) => {
        updateFormField(key, value);
    };

    const invalidate = id => {
        const errorIds = [...state.errorIds];
        const isAlreadyErrored = errorIds.some(error => error.id === id);
        if (isAlreadyErrored) return;
        errorIds.push({ id, message: 'Invalid {label}' });
        setErrorIds(errorIds);
    };

    const nextStep = submit => {
        //validation
        let inputs = Array.from(document.querySelectorAll('input, select'));
        const erroredInputs = inputs
            .map(input => {
                if (input.tagName === 'SELECT' || input.type === 'checkbox')
                    return undefined;
                const min = parseInt(input.getAttribute('minLength') || 0);
                const max = parseInt(input.getAttribute('maxLength'));
                const pattern = input.getAttribute('pattern') || /.+/;
                let error = false;
                //check for character length || min max
                if (input.type === 'text') {
                    if (!isNaN(min) && min >= input.value.length) error = true;
                    if (!isNaN(max) && max < input.value.length) error = true;
                } else if (input.type === 'number') {
                    if (!isNaN(min) && min > (parseInt(input.value) || -1))
                        error = true;

                    if (!isNaN(max) && max < (parseInt(input.value) || -1))
                        error = true;
                }

                //check for regex if both pass
                if (!new RegExp(pattern).test(input.value)) error = true;
                if (error)
                    return {
                        id: input.id,
                        message: `Please enter a valid {label}`,
                    };

                return undefined;
            })
            .filter(val => val !== undefined);
        if (erroredInputs.length > 0) return setErrorIds(erroredInputs);
        if (submit) return submit();
        dispatch({
            type: 'NEXT_STEP',
        });
    };

    const previousStep = () => dispatch({ type: 'PREVIOUS_STEP' });

    return {
        setForm,
        inputChangeHandler,
        nextStep,
        previousStep,
        data: state.data,
        currentStep: state.currentStep,
        errorIds: state.errorIds,
        invalidate,
        getFieldValue,
    };
};

export default useForm;
