import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import PrimaryButton from './Primary';

const Next = ({ text = 'next', onClick }) => {
    return (
        <PrimaryButton
            className="group mx-auto mb-6 w-full gap-x-3 border border-blue-700 uppercase shadow md:w-1/2"
            onClick={onClick}
        >
            {text}
            <ArrowRightIcon className="w-6 transition duration-500 hover:ease-in-out group-hover:translate-x-2" />
        </PrimaryButton>
    );
};

export default Next;
