import { InformationCircleIcon } from '@heroicons/react/24/outline';
import states from 'states-us';
import useForm from '../../../hooks/useForm';
import InputField from '../../shared/form/InputField';
import StepWrapper from '../StepWrapper';

const Address = () => {
    const { inputChangeHandler, data, invalidate } = useForm();
    const handleZIPChange = e => {
        const zipCode = e.target.value.trim();
        inputChangeHandler('zip', zipCode);
        fetchCityAndState(zipCode)
            .then(({ city, state }) => {
                inputChangeHandler('zip', zipCode);
                inputChangeHandler('city', city);
                inputChangeHandler(
                    'state',
                    states.find(
                        x => x.name.toLowerCase() === state.toLowerCase()
                    ).abbreviation
                );
            })
            .catch(err => {
                invalidate('zip');
            });
    };

    const fetchCityAndState = async zipCode => {
        try {
            const response = await fetch(
                `https://api.zippopotam.us/us/${zipCode}`
            );
            const data = await response.json();
            const city = data.places[0]['place name'];
            const state = data.places[0].state;
            return { city, state };
        } catch (error) {
            throw new Error('Failed to fetch city and state.');
        }
    };

    return (
        <StepWrapper
            title={"What's your home address?"}
            subtitle={
                'We verify your location to provide local quotes in your area.'
            }
            subtitleIcon={
                <InformationCircleIcon className="h-6 w-6 text-yellow-500" />
            }
        >
            <div className="mx-auto mb-10 flex w-full flex-col gap-y-4 md:w-1/2">
                <InputField
                    label="address"
                    placeholder="address"
                    name="address"
                    type="text"
                    id={'street'}
                    minLength={4}
                />
                <InputField
                    label="city"
                    placeholder="city"
                    name="city"
                    id={'city'}
                    minimalError={true}
                    value={data.city}
                    readOnly={true}
                />
                <div className="grid grid-cols-2 items-center gap-x-4">
                    <InputField
                        label="state"
                        placeholder="state"
                        name="state"
                        minimalError={true}
                        id={'state'}
                        readOnly={true}
                    />
                    <InputField
                        label="zip code"
                        placeholder="zip code"
                        id={'zip'}
                        minimalError={true}
                        onChange={handleZIPChange}
                        maxLength="5"
                        type="text"
                        pattern="[0-9]*"
                    />
                </div>
            </div>
        </StepWrapper>
    );
};

export default Address;
