import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';

const Back = ({ onClick }) => {
    return (
        <button
            className="group mx-auto mb-3 flex items-center justify-center gap-x-3 text-xl font-bold uppercase text-gray-500 hover:text-gray-400"
            onClick={onClick}
        >
            <ArrowLeftIcon className="w-6 transition duration-500 hover:ease-in-out group-hover:-translate-x-2" />
            back
        </button>
    );
};

export default Back;
