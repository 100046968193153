import useForm from '../../hooks/useForm';
import Layout from '../shared/Layout';
import Address from './Steps/Address';
import CoverageType from './Steps/CoverageType';
import DateOfBirth from './Steps/DateOfBirth';
import Email from './Steps/Email';
import Gender from './Steps/Gender';
import HouseholdSize from './Steps/HouseholdSize';
import Income from './Steps/Income';
import Name from './Steps/Name';

const Steps = [
    CoverageType,
    HouseholdSize,
    Income,
    Gender,
    DateOfBirth,
    Address,
    Name,
    Email,
];

const Form = () => {
    const { currentStep } = useForm();
    const CurrentStep = Steps[currentStep];
    const progressPercentage = (currentStep / (Steps.length - 1)) * 84 + 12;

    return (
        <Layout>
            <section className="mx-auto w-full py-8 md:w-4/5 md:py-24">
                <div className="mx-auto mb-3 md:w-1/2">
                    <b>{progressPercentage.toFixed(0)}%</b> complete
                    <div className="mb-4 h-1.5 w-full rounded-full bg-gray-200">
                        <div
                            className="h-1.5 rounded-full bg-blue-600 dark:bg-blue-500"
                            style={{ width: `${progressPercentage}%` }}
                        ></div>
                    </div>
                </div>
                <CurrentStep />
            </section>
        </Layout>
    );
};

export default Form;
