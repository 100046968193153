import classNames from 'classnames';
import React from 'react';

const PrimaryButton = ({
    href,
    onClick,
    children,
    className = '',
    size = 'medium',
    iconSrc,
}) => {
    const baseClasses =
        'rounded-md bg-blue-500 hover:bg-blue-600 text-xl font-bold uppercase text-white flex items-center justify-center gap-x-1';

    const sizeClasses = classNames({
        'px-8 py-2': size === 'small',
        'px-10 py-5': size === 'medium',
        'px-12 py-6': size === 'large',
    });

    const combinedClasses = classNames(baseClasses, sizeClasses, className);

    if (href) {
        return (
            <a href={href} className={combinedClasses}>
                {iconSrc && <img src={iconSrc} className="h-12" alt="Icon" />}
                {children}
            </a>
        );
    }

    return (
        <button onClick={onClick} className={combinedClasses}>
            {iconSrc && <img src={iconSrc} className="h-12" alt="Icon" />}
            {children}
        </button>
    );
};

export default PrimaryButton;
