import React from 'react';
import useForm from '../../../hooks/useForm';
import PrimaryButton from '../../shared/button/Primary';
import StepWrapper from '../StepWrapper';

const Gender = () => {
    const { inputChangeHandler, nextStep } = useForm();

    return (
        <StepWrapper title={"What's your gender?"} hideButtons={true}>
            <PrimaryButton
                className="mx-auto mb-3 w-full border border-blue-700 uppercase shadow md:w-1/2"
                onClick={() => {
                    inputChangeHandler('gender', 'male');
                    nextStep();
                }}
            >
                male
            </PrimaryButton>
            <PrimaryButton
                className="mx-auto mb-6 w-full border border-blue-700 uppercase shadow md:w-1/2"
                onClick={() => {
                    inputChangeHandler('gender', 'female');
                    nextStep();
                }}
            >
                female
            </PrimaryButton>
        </StepWrapper>
    );
};

export default Gender;
