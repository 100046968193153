export const FormReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.payload };
        case 'UPDATE_INPUT_FIELD':
            const { id, value } = action.payload;
            const keys = id.split('-');
            let errorIds = state.errorIds;
            if (
                value.length > 0 &&
                state.errorIds.some(errorId => errorId.id === id)
            )
                errorIds = state.errorIds.filter(errorId => errorId.id !== id);
            const data = { ...state.data };
            if (keys.length === 1) data[id] = value;
            else data[keys[0]] = { ...data[keys[0]], [keys[1]]: value };
            return { ...state, data, errorIds };
        case 'NEXT_STEP':
            return { ...state, currentStep: state.currentStep + 1 };
        case 'PREVIOUS_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        case 'SET_ERROR_IDS':
            return { ...state, errorIds: action.payload };
        default:
            return state;
    }
};
