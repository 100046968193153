import InputField from '../../shared/form/InputField';
import StepWrapper from '../StepWrapper';

const Component = () => {
    return (
        <StepWrapper title={"What's your full name?"}>
            <div className="mx-auto mb-10 flex w-full flex-col gap-y-4 md:w-1/2">
                <InputField
                    label="first name"
                    placeholder="first name"
                    id="firstname"
                    type="text"
                />
                <InputField
                    label="last name"
                    placeholder="last name"
                    id="lastname"
                    type="text"
                />
            </div>
        </StepWrapper>
    );
};

export default Component;
