import classNames from 'classnames';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import useForm from '../../../hooks/useForm';

const InputField = ({
    id,
    label,
    type = 'text',
    placeholder,
    className,
    minLength = 0,
    maxLength,
    onChange,
    pattern,
    minimalError,
    format,
    disabled = false,
    readOnly = false,
}) => {
    const { errorIds } = useForm();
    const error = errorIds.find(e => e.id === id);
    const { inputChangeHandler, getFieldValue } = useForm();

    return (
        <div className={`relative ${className}`}>
            <label
                htmlFor={id}
                className={classNames(
                    'absolute left-2 top-1.5 text-[.6rem] uppercase md:text-sm',
                    {
                        'font-medium text-red-500': error,
                        'text-gray-500': !error,
                    }
                )}
            >
                {error
                    ? minimalError
                        ? `Invalid ${label}`
                        : error.message.replace('{label}', label)
                    : label}
            </label>
            {format ? (
                <PatternFormat
                    className={classNames(
                        'leading-1 w-full rounded-md border py-5 pl-5 text-lg uppercase',
                        {
                            'border-red-500': error,
                            'border-gray-300': !error,
                        }
                    )}
                    type={type}
                    format={format}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    id={id}
                    pattern={pattern}
                    value={getFieldValue(id)}
                    onChange={e =>
                        onChange
                            ? onChange(e)
                            : inputChangeHandler(id, e.target.value)
                    }
                    disabled={disabled}
                    readOnly={readOnly}
                />
            ) : (
                <input
                    className={classNames(
                        'leading-1 w-full rounded-md border py-5 pl-5 text-lg',
                        {
                            'border-red-500': error,
                            'border-gray-300': !error,
                        }
                    )}
                    type={type}
                    format={format}
                    placeholder={placeholder}
                    minLength={minLength}
                    maxLength={maxLength}
                    id={id}
                    pattern={pattern}
                    value={getFieldValue(id)}
                    onChange={e =>
                        onChange
                            ? onChange(e)
                            : inputChangeHandler(id, e.target.value)
                    }
                    disabled={disabled}
                    readOnly={readOnly}
                />
            )}
        </div>
    );
};

export default InputField;
