import axios from 'axios';
import GHL_CUSTOM_FIELDS from '../utils/ghl_custom_fields';

const useContacts = () => {
    const createContact = async payload => {
        const data = {
            ...payload,
            dob: `${String(payload.dob.month).padStart(2, '0')}-${String(payload.dob.day).padStart(2, '0')}-${payload.dob.year}`,
        };
        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/contacts`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };
        return (await axios.request(config)).data;
    };

    const getContact = async id => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/contacts/${id}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching contact:', error);
            throw error;
        }
    };

    const sendContactToGHL = async id => {
        try {
            const contactDoc = await getContact(id);
            const contact = {
                email: contactDoc.email,
                phone: contactDoc.phone,
                firstName: contactDoc.firstname,
                lastName: contactDoc.lastname,
                name: `${contactDoc.firstname} ${contactDoc.lastname}`,
                dateOfBirth: formatDate(contactDoc.dob),
                address1: contactDoc.street,
                state: contactDoc.state,
                country: 'US',
                city: contactDoc.city,
                postalCode: contactDoc.zip,
                tags: ['insurandemadesimple.com', process.env.NODE_ENV],
                customField: {
                    [GHL_CUSTOM_FIELDS['contact.household_annual_income']]:
                        contactDoc.income,
                    [GHL_CUSTOM_FIELDS['contact.gender']]: contactDoc.gender,
                    [GHL_CUSTOM_FIELDS['contact.family_type']]:
                        contactDoc.coverage_type,
                    [GHL_CUSTOM_FIELDS['contact.household_size']]:
                        contactDoc.household_size,
                },
            };

            const res = await fetch(process.env.REACT_APP_GHL_URL, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: process.env.REACT_APP_TOKEN,
                    Version: process.env.REACT_APP_VERSION,
                },
                method: 'POST',
                body: JSON.stringify(contact),
            });

            if (res.status === 200)
                console.log('Contact published on GHL successfully 🎉');
            else console.error('Failed to publish contact on GHL');
        } catch (error) {
            console.error('Error sending contact to GHL:', error);
            throw error;
        }
    };

    const formatDate = dateString => {
        const date = new Date(dateString);

        const monthAbbreviations = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];

        // Extract day, month, and year components from the date object
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date string as "DD Month YYYY"
        const formattedDate = `${day < 10 ? '0' + day : day} ${monthAbbreviations[monthIndex]} ${year}`;
        return formattedDate;
    };
    return {
        createContact,
        sendContactToGHL,
        getContact,
    };
};

export default useContacts;
