import useForm from '../../../hooks/useForm';
import InputField from '../../shared/form/InputField';
import StepWrapper from '../StepWrapper';

const Email = () => {
    const { data } = useForm();
    return (
        <StepWrapper
            title={`Thanks ${data.firstname}, your quote is ready! Where do we send it to?`}
            submit={true}
        >
            <div className="mx-auto mb-10 flex w-full flex-col gap-y-4 md:w-1/2">
                <InputField
                    type="text"
                    label="email"
                    placeholder="email"
                    id="email"
                    pattern={
                        '^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\\.[a-zA-Z]{2,4}$'
                    }
                />
                <InputField
                    type="text"
                    label="phone"
                    placeholder="phone"
                    id="phone"
                    format={'+1 (###) ###-####'}
                />
            </div>
        </StepWrapper>
    );
};

export default Email;
