import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import states from 'states-us/dist';
import AgentImg from '../../assets/images/agent.webp';
import PhoneWhite from '../../assets/images/phone-white.webp';
import PulseAnimation from '../../assets/images/pulse.gif';
import useForm from '../../hooks/useForm';
import PrimaryButton from '../shared/button/Primary';
import Dropdown from '../shared/form/Dropdown';
import InputField from '../shared/form/InputField';
import Layout from '../shared/Layout';
import AetnaLogo from '../svgs/aetna';
import AnthemLogo from '../svgs/anthem';
import CignaLogo from '../svgs/cigna';
import HumanaLogo from '../svgs/humana';

const Home = () => {
    const navigate = useNavigate();
    const { inputChangeHandler, invalidate, nextStep } = useForm();
    const handleZIPChange = e => {
        const zipCode = e.target.value.trim();
        inputChangeHandler('zip', zipCode);
        fetchCityAndState(zipCode)
            .then(({ city, state }) => {
                inputChangeHandler('zip', zipCode);
                inputChangeHandler('city', city);
                inputChangeHandler(
                    'state',
                    states.find(
                        x => x.name.toLowerCase() === state.toLowerCase()
                    ).abbreviation
                );
            })
            .catch(err => {
                invalidate('zip');
            });
    };

    const fetchCityAndState = async zipCode => {
        try {
            const response = await fetch(
                `https://api.zippopotam.us/us/${zipCode}`
            );
            const data = await response.json();
            const city = data.places[0]['place name'];
            const state = data.places[0].state;
            return { city, state };
        } catch (error) {
            throw new Error('Failed to fetch city and state.');
        }
    };

    return (
        <Layout>
            <section className="mb-6">
                <div className="flex flex-col items-center justify-center gap-y-5 rounded-xl bg-gray-100 px-4 pb-14 pt-20">
                    <h1 className="text-3xl font-bold text-gray-800 lg:text-5xl">
                        Health plan options 2024
                    </h1>
                    <h3 className="text-md uppercase lg:text-xl">
                        ENTER ZIP CODE & COMPARE RATES TO FIND HOW MUCH YOU MAY
                        SAVE!*
                    </h3>
                    <div className="mb-5 flex flex-col items-center gap-x-3 lg:flex-row lg:items-stretch">
                        <InputField
                            label="zip code"
                            placeholder="zip code"
                            id={'zip'}
                            onChange={handleZIPChange}
                            className={'mb-2 w-2/3 lg:mb-0'}
                            minimalError={true}
                            maxLength="5"
                            pattern="[0-9]*"
                        />
                        <PrimaryButton
                            className="w-full"
                            onClick={() => nextStep(() => navigate('/form'))}
                        >
                            start my quote
                        </PrimaryButton>
                    </div>
                    <div className="lg:text-center">
                        <div className="flex items-start justify-center text-sm">
                            <CheckBadgeIcon className="mr-1 h-6 w-6 text-green-600" />
                            <p>
                                We've helped{' '}
                                <b className="mx-1">12,753 people</b> from PB
                                this month.
                            </p>
                        </div>
                        <p className="ml-6 text-sm">
                            We work with <b>200 partners</b>, including the
                            brands below, to offer plans.
                        </p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center gap-x-2 gap-y-2">
                        <button className="rounded-lg border bg-white p-5 hover:shadow-global">
                            <AnthemLogo size={20} />
                        </button>
                        <button className="rounded-lg border bg-white p-5 hover:shadow-global">
                            <CignaLogo size={20} />
                        </button>
                        <button className="rounded-lg border bg-white p-5 hover:shadow-global">
                            <HumanaLogo size={20} />
                        </button>
                        <button className="rounded-lg border bg-white p-5 hover:shadow-global">
                            <AetnaLogo size={20} />
                        </button>
                    </div>
                </div>
            </section>
            <section className="mb-6">
                <div className="flex flex-col items-center justify-center gap-y-5 rounded-xl bg-gray-100 px-4 py-14">
                    <h1 className="text-4xl font-bold text-gray-800">
                        Click your state below
                    </h1>
                    <h3 className="text-xl">
                        To get <b>FREE</b> health plan quotes & save!
                    </h3>
                    <div className="mx-auto my-4 hidden w-5/6 lg:block">
                        <div className="flex flex-wrap items-center justify-center gap-5">
                            {states.map((state, index) => (
                                <button
                                    className="w-1/6 truncate rounded-lg border border-gray-200 bg-transparent p-5 px-2 px-4 py-1 py-2 text-sm font-bold hover:border-blue-500 hover:bg-blue-500 hover:text-white"
                                    key={index}
                                    onClick={() => navigate('/form')}
                                >
                                    {state.name.length >= 17
                                        ? state.name.slice(0, 17) + '...'
                                        : state.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:hidden">
                        <Dropdown
                            onChange={() => navigate('/form')}
                            label={'State'}
                            options={states}
                        />
                    </div>
                </div>
            </section>
            <section className="mb-6">
                <div className="flex flex-col items-center justify-center gap-y-5 rounded-xl bg-gray-100 px-4 pb-4 pt-14 lg:flex-row">
                    <img src={AgentImg} className="w-1/2 lg:w-1/4" />
                    <div className="flex flex-col items-center justify-center gap-y-6">
                        <h1 className="text-center text-2xl font-bold text-gray-800">
                            Prefer to speak with an expert?
                        </h1>
                        <h3 className="leading-1 text-center text-lg lg:text-lg">
                            Speak with a licensed agent and get knowledgeable
                            <br />
                            advice on choosing a plan that's right for you.
                        </h3>
                        <PrimaryButton
                            href="tel:1-866-650-0567"
                            iconSrc={PhoneWhite}
                            size="small"
                        >
                            1-866-650-0567
                        </PrimaryButton>
                        <div className="flex items-center justify-center gap-x-2 text-sm font-semibold">
                            <img
                                src={PulseAnimation}
                                alt="pulse"
                                className="h-4"
                            />
                            Call for free live assistance
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="rounded-xl bg-gray-100 py-10 text-center">
                    <h2 className="mb-3 text-2xl font-bold">
                        Compare health insurance quotes
                    </h2>
                    <p className="mb-2 font-medium">
                        <span className="text-blue-500">NO OBLIGATION</span> TO
                        ENROLL
                    </p>
                    <div className="my-5 flex flex-col items-center justify-center gap-x-3 lg:flex-row">
                        <InputField
                            label="zip code"
                            placeholder="zip code"
                            id={'zip'}
                            onChange={handleZIPChange}
                            className={'mb-2 w-1/2 lg:mb-0 lg:w-1/4'}
                            minimalError={true}
                            maxLength="5"
                            pattern="[0-9]*"
                        />
                        <PrimaryButton
                            onClick={() => nextStep(() => navigate('/form'))}
                        >
                            start my quote
                        </PrimaryButton>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Home;
