const GHL_CUSTOM_FIELDS = {
    'contact.coverage_amount': 'mVbD4f31fwPnCVWLQkPx',
    'contact.gender': 'fvWqXWxwD7tYxpcyvOGk',
    'contact.type_of_protection': 'zQj7F9KLUeW0LMCGojwh',
    'contact.family_type': 'KaqUHV5ZVJ8IIP1zIl9i',
    'contact.household_annual_income': 'u3gAKNfSqGRRPdxFxzua',
    'contact.household_size': 'OpEbshZwcKZlZo3DOKgz',
};

export default GHL_CUSTOM_FIELDS;
