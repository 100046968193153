import React from 'react';
import BbbLogo from '../../assets/images/bbb-logo.png';
import Logo from '../svgs/logo';

const Footer = () => {
    return (
        <footer className="divide-y divide-solid pb-8 pt-2">
            <div className="p y-2 flex items-center justify-between">
                <Logo size={150} />
                <img src={BbbLogo} alt="bbb-logo" className="h-16" />
            </div>
            <div className="py-3">
                <p className="mb-4">
                    The provided text is a disclosure statement for{' '}
                    <a
                        href="https://quote.benefitsritenow.com/"
                        className="text-blue-600 underline"
                    >
                        quote.benefitsritenow.com
                    </a>
                    , operated by Vivid Marketing Group, LLC. Here's a breakdown
                    of the key points:
                </p>

                <p className="mb-4">
                    Ownership and Operation: quote.benefitsritenow.com is owned
                    and operated by Vivid Marketing Group, LLC. The insurance
                    application invitations are made through Cornerstone
                    Insurance Agency LLC, a licensed subsidiary.
                </p>

                <p className="mb-4">
                    Licensing Information: Cornerstone Insurance Agency
                    licensing details can be accessed via a provided link.
                </p>

                <p className="mb-4">
                    Permission to Contact: By submitting your information on the
                    website, you agree to be contacted by an agent with further
                    details on health plan costs and coverage.
                </p>

                <p className="mb-4">
                    Plan Options: The website offers various health plan
                    options, including:
                </p>

                <ul className="mb-4 ml-4 list-inside list-disc">
                    <li>Major Medical Plans</li>
                    <li>Short Term Plans</li>
                    <li>Fixed Indemnity Plans</li>
                    <li>Community/Cost-Sharing Plans</li>
                </ul>

                <p className="mb-4">
                    Informational Purpose: Descriptions of plans are for
                    informational purposes only and may change. Availability of
                    plans can vary by state.
                </p>

                <p className="mb-4">
                    Contact for Details: For a full description of available
                    plans, eligibility, and to request a copy of the policy, you
                    should call 1-561-437-8798.
                </p>

                <p className="mb-4">
                    Non-Affiliation: quote.benefitsritenow.com is not affiliated
                    with or endorsed by the U.S. government or the federal
                    Medicare program.
                </p>

                <p className="mb-4">
                    <a
                        className="text-blue-600 underline"
                        href="/terms-and-conditions"
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        className="text-blue-600 underline"
                        href="/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    : Use of the site indicates agreement with the site's{' '}
                    <a
                        className="text-blue-600 underline"
                        href="/terms-and-conditions"
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        className="text-blue-600 underline"
                        href="/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    .
                </p>

                <p className="mb-4">
                    This summary highlights the important legal and operational
                    details related to the services provided by
                    quote.benefitsritenow.com, ensuring that users are aware of
                    the nature of the service, their consent to be contacted,
                    and the regulatory and affiliation status of the website.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
