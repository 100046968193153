import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react';
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useForm from '../../../hooks/useForm';

const Dropdown = ({
    label,
    options,
    defaultValue,
    onChange,
    className,
    id,
}) => {
    const [selected, setSelected] = useState(defaultValue || options[0]);
    const { inputChangeHandler, data } = useForm();
    const handleChange = selectedOption => {
        setSelected(selectedOption);
        onChange
            ? onChange(selectedOption.value)
            : inputChangeHandler(id, selectedOption.value);
    };

    useEffect(() => {
        if (id && data[id].length === 0)
            inputChangeHandler(id, defaultValue.value);
    }, []);

    return (
        <Listbox value={selected} onChange={handleChange} className={className}>
            {({ open }) => (
                <>
                    <div className="relative">
                        <Label className="absolute left-5 top-1.5 z-10 text-xs uppercase text-gray-500">
                            {label}
                        </Label>
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-5 pl-5 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                            <span className="block truncate text-lg">
                                {selected.name}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                {open ? (
                                    <ChevronUpIcon
                                        className="h-6 w-6 text-blue-500"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <ChevronDownIcon
                                        className="h-6 w-6 text-blue-500"
                                        aria-hidden="true"
                                    />
                                )}
                            </span>
                        </ListboxButton>

                        <Transition
                            show={open}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((option, index) => (
                                    <ListboxOption
                                        key={index}
                                        className={({ focus }) =>
                                            classNames(
                                                focus
                                                    ? 'bg-blue-600 text-white'
                                                    : '',
                                                !focus ? 'text-gray-900' : '',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={option}
                                    >
                                        {({ selected, focus }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                        'block truncate text-base'
                                                    )}
                                                >
                                                    {option.name}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            focus
                                                                ? 'text-white'
                                                                : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

export default Dropdown;
