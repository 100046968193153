import React from 'react';
import useForm from '../../../hooks/useForm';
import Dropdown from '../../shared/form/Dropdown';
import { default as StepWrapper, default as Title } from '../StepWrapper';

const memberOptions = [
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6+', value: '6+' },
];

const HouseholdSize = () => {
    const { data } = useForm();
    return (
        <StepWrapper title={"What's your household size?"}>
            <div className="mx-auto mb-10 md:w-1/2">
                <Dropdown
                    label="assigned to"
                    id={'household_size'}
                    options={
                        data.coverage_type === 'individual'
                            ? memberOptions.slice(0, 1)
                            : memberOptions
                    }
                    defaultValue={
                        data.coverage_type === 'individual'
                            ? memberOptions[0]
                            : memberOptions[1]
                    }
                />
            </div>
        </StepWrapper>
    );
};

export default HouseholdSize;
