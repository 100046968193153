import { createContext, useContext, useReducer } from 'react';
import { FormReducer } from './reducer/FormReducer';

const initialFormState = {
    coverage_type: '',
    household_size: '',
    income: '',
    gender: '',
    dob: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    uuid: '',
    isCompleted: false,
};

const FormContext = createContext({
    state: {
        data: initialFormState,
        currentStep: 0,
        errorIds: [],
    },
    dispatch: () => {},
});

export const useFormContext = () => useContext(FormContext);

export const FormContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(FormReducer, {
        currentStep: 0,
        data: initialFormState,
        errorIds: [],
    });

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};
