import React from 'react';

const Anthem = ({ size = 100, classes }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width="auto"
        viewBox="0 0 93 22"
        fill="none"
        className={classes}
    >
        <g id="Coventry">
            <g id="Anthem,_Inc">
                <path
                    id="Fill-4"
                    d="M63.7247 13.313H59.2055C59.2055 12.9944 59.2055 11.9904 59.2055 11.9904C59.2055 11.1215 59.4472 10.4071 59.9029 9.93401C60.3027 9.51888 60.8607 9.28718 61.4558 9.28718C62.9064 9.29684 63.7154 10.2526 63.7154 11.9904C63.7247 11.9904 63.7247 12.9944 63.7247 13.313ZM65.3799 9.79885C64.4129 8.91067 63.0645 8.44727 61.4837 8.44727C60.0703 8.44727 58.6661 9.11341 57.6246 10.2912C56.5088 11.5366 55.895 13.2551 55.8857 15.1087C55.8857 16.9333 56.5181 18.6035 57.6804 19.8103C58.787 20.9688 60.2655 21.5963 61.8464 21.5963C64.8871 21.5866 66.5981 19.2503 66.8957 17.0878L66.9143 16.9719H65.9751L65.9658 17.0588C65.7519 18.8159 64.3106 20.6888 62.0602 20.6791C60.2655 20.6791 59.2334 19.4048 59.2241 17.1843C59.2241 17.1843 59.2241 14.7611 59.2241 14.2398H67.1002V14.1336C67.0352 12.2414 66.4586 10.7836 65.3799 9.79885Z"
                    fill="#0173BC"
                />
                <path
                    id="Fill-6"
                    d="M40.7019 18.7492H39.8185L39.7999 18.8264C39.6976 19.4057 39.3442 20.3808 38.2098 20.3711C37.3264 20.3711 36.8707 19.7243 36.8707 18.4596C36.8707 18.4596 36.8707 10.6107 36.8707 9.64529H39.7813V9.54875V8.88261V8.78607H36.8893V4.97266L36.787 4.98231C36.1082 5.05954 34.7227 5.16574 33.9974 5.16574H33.9044V8.78607H31.9795V9.65494H33.9137V17.33C33.9137 20.1587 35.0853 21.5875 37.3822 21.5875C40.0231 21.5875 40.5717 19.5022 40.6833 18.8651L40.7019 18.7492Z"
                    fill="#0173BC"
                />
                <path
                    id="Fill-7"
                    d="M7.7553 5.42566L10.545 13.6993H5.1516L7.7553 5.42566ZM30.2215 20.38V12.5794C30.2215 11.3726 29.8495 10.3396 29.1335 9.59627C28.4175 8.8529 27.3853 8.44742 26.2323 8.44742C24.6515 8.44742 23.3868 9.10391 22.4569 10.3879L22.429 8.89152V8.79498H17.6959V9.64455H19.5556V20.38H16.2359L9.41981 0.0675794L9.40121 0H8.56431L1.87838 20.38H0V21.2296H5.57006V20.38H3.00355L4.85404 14.5778H10.8147L12.693 20.38H10.0986V21.2296H24.3167V20.38H22.4569V14.4234C22.4662 10.8706 24.2888 9.57697 25.5163 9.57697C26.7065 9.57697 27.3109 10.3783 27.3109 11.9519C27.3109 11.9519 27.3109 19.4339 27.3109 20.38H25.572V21.2296H32.1092V20.38H30.2215Z"
                    fill="#0173BC"
                />
                <path
                    id="Fill-8"
                    d="M53.5895 20.3787V12.4526C53.5895 10.599 52.5016 8.44615 49.4608 8.44615C47.0989 8.44615 45.8714 9.63361 45.3507 10.2515V0.0566406H40.6082V0.944828H42.3843V20.3787H40.4688V21.2283H47.1826V20.3787H45.3507V14.3449C45.3507 12.2596 46.0388 11.1107 46.6153 10.5121C47.1733 9.93289 47.8893 9.61431 48.6239 9.61431C50.2512 9.61431 50.5953 11.0045 50.6046 12.1727C50.6046 12.1727 50.6046 19.4519 50.6046 20.3787H48.7727V21.2283H55.4865V20.3787H53.5895Z"
                    fill="#0173BC"
                />
                <path
                    id="Fill-9"
                    d="M89.1128 20.3779H87.2902V11.9691C87.2902 9.78724 85.8488 8.43565 83.5427 8.43565C81.3574 8.43565 80.1672 9.56519 79.6185 10.3182C79.0141 9.08248 77.8332 8.426 76.2058 8.43565C74.6436 8.43565 73.2488 9.03421 72.3189 10.0769L72.291 8.87975V8.7832H67.4648V9.63277H69.4362V20.3682H67.4927V21.2178H74.0578V20.3682H72.384V14.4406C72.4398 12.7125 72.5142 11.6022 73.1093 10.7527C73.6207 10.0479 74.439 9.60381 75.2387 9.60381C76.2244 9.60381 76.5778 10.3761 76.615 10.463C76.8661 10.9554 76.8661 11.805 76.8661 12.6256V20.3682H75.1179V21.2178H81.5527V20.3682H79.8696V14.4406C79.9254 12.7125 79.9998 11.6022 80.5949 10.7527C81.1064 10.0479 81.9247 9.60381 82.7244 9.60381C83.7101 9.60381 84.0634 10.3761 84.1006 10.463C84.3517 10.9554 84.3517 11.805 84.3517 12.6256V20.3682H82.6128V21.2178H89.2057V20.3682H89.1128"
                    fill="#0173BC"
                />
                <path
                    id="Fill-10"
                    d="M91.419 19.4927C91.6608 19.4927 92.0048 19.5313 92.0048 19.1934C92.0048 18.9424 91.8003 18.9038 91.5864 18.9038H91.1028V19.4927H91.419ZM91.1121 20.5161H90.889V18.6914H91.6422C92.0978 18.6914 92.2559 18.8652 92.2559 19.1934C92.2559 19.541 92.0141 19.6761 91.7166 19.7051L92.2373 20.5161H91.9862L91.4934 19.7051H91.1214V20.5161H91.1121ZM92.7488 19.6086C92.7488 18.8362 92.2094 18.2763 91.4655 18.2763C90.7123 18.2763 90.1729 18.8362 90.1729 19.6086C90.1729 20.3906 90.7123 20.9505 91.4655 20.9505C92.2094 20.9505 92.7488 20.3906 92.7488 19.6086ZM89.9219 19.6086C89.9219 18.7397 90.61 18.0156 91.4655 18.0156C92.3117 18.0156 92.9998 18.73 92.9998 19.6086C92.9998 20.4871 92.3117 21.2112 91.4655 21.2112C90.61 21.2112 89.9219 20.4968 89.9219 19.6086Z"
                    fill="#0173BC"
                />
            </g>
        </g>
    </svg>
);

export default Anthem;
